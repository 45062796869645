import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import ReactGA from 'react-ga'

import { Home, OurPrograms, Faq, NotFound, Videos, Alumni, ContactUs, Thanks, PrivacyPolicy } from '@pages'
import { ROUTES } from '@routes'
import stores from '@stores'

import reportWebVitals from './reportWebVitals'
import './index.scss'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate replace to={ROUTES.Home} />,
  },
  {
    path: ROUTES.Home,
    element: <Home />,
  },
  {
    path: ROUTES.OurPrograms,
    element: <OurPrograms />
  },
  {
    path: `${ROUTES.OurPrograms}/:id`,
    element: <OurPrograms />
  },
  {
    path: ROUTES.Faq,
    element: <Faq />
  },
  {
    path: ROUTES.Videos,
    element: <Videos />
  },
  {
    path: ROUTES.Alumni,
    element: <Alumni />
  },
  {
    path: ROUTES.ContactUs,
    element: <ContactUs />
  },
  {
    path: ROUTES.Thanks,
    element: <Thanks />
  },
  {
    path: ROUTES.NotFound,
    element: <NotFound />
  },
  {
    path: ROUTES.PrivacyPolicy,
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.NotFound} replace />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <StoreProvider store={stores}>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
)

ReactGA.initialize('UA-106050962-1')
ReactGA.pageview(window.location.pathname + window.location.search)
reportWebVitals()