import { FC, useEffect, useState } from 'react'
import axios from 'axios'

type Data = {
  id: string
  caption: string
  media_type: string
  media_url: string
  username: string
  timestamp: string
}

export const Instagram: FC = () => {
  const [mainposts, setMainPosts] = useState<Data[]>([])
  const [posts, setPosts] = useState<Data[]>([])

  const IG_USER_ID = '6329432607079619'
  const IG_ACCESS_TOKEN = 'IGQVJVQ2F1Tk1URXEwTlBrWFEzODJoRW8wMWNaRFBWRko1WjhocXp4MkxVMENTQWNRaDF0anpyZAG9OU3J0cVNNNzFCUEswcFZA4OXJPS1R3Yl9BOGt0VWRqNzhjcW1pOWFEODNiR010aXdnMmtaR3pIQgZDZD'

  useEffect(() => {
    getInstagramPost()
  }, [])

  const getInstagramPost = () => {
    const params = {
      limit: 100,
      page: 1
    }

    axios.get('https://graph.instagram.com/' + IG_USER_ID + '/media?access_token=' + IG_ACCESS_TOKEN + '&fields=caption,media_url,media_type', { params })
      .then(res => {
        const { data } = res.data
        if (data.length > 0) setMainPosts(data.slice(0, 1))
        else if (data.length > 1) setPosts(data.slice(1, 3))
        return data
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="bg-gradient-to-b from-[#314969] to-[#203147]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 py-10 md:py-10">
        <div className="flex flex-wrap-reverse">
          <div className="md:p-10 w-full md:w-5/12 mt-5 md:mt-20">
            <p className="text-center md:text-left text-xl md:text-3xl font-semibold text-white leading-relaxed">
              We Invest In The Innovative People That Push Beyond What’s Really Obvious
            </p>
          </div>
          <div className="md:p-10 w-full md:w-7/12">
            <img
              className="w-full h-auto"
              src={require('@assets/images/laptop.png')}
              alt="John Robert Powers"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 mt-10">
          <div className="relative">
            {mainposts.length > 0 &&
              <a href="https://www.instagram.com/jrpindonesia" target="_blank" rel="noreferrer">
                <div style={{ backgroundColor: '#ffffff', padding: 5 }}>
                  <div className="grid grid-cols-1 md:grid-cols-7 ">
                    <img
                      style={{ marginLeft: 5, width: 25, borderRadius: 15, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                      src={require('@assets/images/jrp_icon.jpg')}
                      alt="John Robert Powers"
                    />
                    <p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>jrpindonesia</p>
                  </div>
                  <img
                    className="w-full h-auto"
                    src={mainposts?.[0]?.media_url}
                    alt="John Robert Powers"
                  />
                  <p style={{ fontSize: 12, marginTop: 10 }}>{mainposts?.[0]?.caption}</p>
                </div>
              </a>
            }
            <img
              className="hidden md:block absolute -top-20 -right-10 w-auto h-20"
              src={require('@assets/images/suriken.png')}
              alt="John Robert Powers"
            />
          </div>

          {posts.map((item, index) =>
            <a
              key={index}
              href="https://www.instagram.com/jrpindonesia"
              target="_blank"
              rel="noreferrer"
            >
              <div style={{ backgroundColor: '#ffffff', padding: 5 }} className='md:mt-10'>
                <div className="grid grid-cols-1 md:grid-cols-7 ">
                  <img
                    style={{ marginLeft: 5, width: 25, borderRadius: 15, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                    src={require('@assets/images/jrp_icon.jpg')}
                    alt="John Robert Powers"
                  />
                  <p style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>jrpindonesia</p>
                </div>
                <img
                  className="w-full h-auto"
                  src={item?.media_url}
                  alt="John Robert Powers"
                />
                <p style={{ fontSize: 12, marginTop: 10 }}>
                  {item?.caption}
                </p>
              </div>
            </a>
          )}
        </div>
        <div className="text-center mt-10 md:my-20">
          <a href="https://www.instagram.com/jrpindonesia" target="_blank" rel="noreferrer">
            <button className="bg-white rounded-md px-5 py-2 text-neutral-900 font-medium">
              Visit Our Instagram &gt;
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
