import { Fragment, FC } from 'react'
import { ScrollRestoration } from 'react-router-dom'

import { Topbar, Footer } from '@components'

import { Banner, BranchOffice, Instagram, Program, Testimonial, VisionMission } from './components'

export const Home: FC = () => {
  return (
    <Fragment>
      <Topbar active={1} />
      <Banner />
      <VisionMission />
      <Program />
      <BranchOffice />
      <Testimonial />
      <Instagram />
      <Footer active={1} />
      <ScrollRestoration />
    </Fragment >
  )
}