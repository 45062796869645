import { FC, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/outline'

import { useStoreActions, useStoreState } from '@stores'

export const Testimonial: FC = () => {
  const { fetchTestimonial } = useStoreActions(action => action.testimonial)
  const { data } = useStoreState(state => state.testimonial)

  const sliderRef = useRef<Slider>(null)

  useEffect(() => {
    fetchTestimonial({
      page: 1,
      limit: 100
    })
  }, [fetchTestimonial])

  const setting = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 py-8 md:py-24 overflow-x-hidden">
      <div className="md:flex md:flex-row md:items-center md:space-x-40">
        <h1 className="w-full md:w-48 text-2xl font-semibold text-black leading-relaxed">
          What They Say
          About Us
        </h1>
        <p className="w-full md:w-80 text-md leading-relaxed text-neutral-600">
          Everyone trust with us to help them in develop
          their personalities
        </p>
        <div className="flex flex-1 justify-end gap-2 md:gap-5">
          <ArrowLeftCircleIcon
            className="h-10 w-10 flex-shrink-0 text-neutral-300 hover:text-neutral-500 cursor-pointer"
            aria-hidden="true"
            onClick={() => sliderRef?.current?.slickPrev()}
          />
          <ArrowRightCircleIcon
            className="h-10 w-10 flex-shrink-0 text-neutral-300 hover:text-neutral-500 cursor-pointer"
            aria-hidden="true"
            onClick={() => sliderRef?.current?.slickNext()}
          />
        </div>
      </div>
      <div className="-mx-5">
        <Slider
          ref={sliderRef}
          {...setting}
        >
          {data.map((item, index) =>
            <div
              key={index}
              className="px-5 pt-5 pb-10 md:py-10 h-full cursor-grab"
            >
              <div className="flex flex-row md:h-full bg-white p-3 md:p-6 rounded-xl gap-3 shadow-xl border border-neutral-200">
                <div className="w-20 h-20 flex-shrink-0">
                  <Zoom>
                    <img
                      className="w-20 h-20 rounded-md"
                      src={item?.image?.url}
                      alt="John Robert Powers"
                    />
                  </Zoom>
                </div>
                <div>
                  <h2 className="font-semibold text-md text-neutral-900">
                    {item?.name}
                  </h2>
                  <p className="text-sm text-neutral-900 mt-1">
                    {item?.occupation}
                  </p>
                  <p className="text-sm text-neutral-900 mt-4 mb-5">
                    {item?.testimonial}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  )
}
