import { FC } from 'react'

export const NotFound: FC = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <div className="text-4xl font-bold">404</div>
        <div className="text-xl font-bold">Page Not Found</div>
      </div>
    </div>
  )
}