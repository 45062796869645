import { action, thunk } from 'easy-peasy'

import { FetchStatus } from '@types'

import { TestimonialModel, testimonialService } from '.'
import { AxiosError } from 'axios'

export const testimonial: TestimonialModel = {
  data: [],
  status: FetchStatus.IDLE,
  error: '',

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchTestimonial: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await testimonialService.fetchTestimonial(payload)
      action.setStatus(FetchStatus.LOADED)
      action.setData(res.data)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setStatus(FetchStatus.ERROR)
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
      } else {
        console.log(e)
      }
    } finally {
      setTimeout(() => {
        action.setError('')
        action.setStatus(FetchStatus.IDLE)
      }, 10)
    }
  })
}
