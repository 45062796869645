import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchBannerParams, Banner } from '.'

const fetchBanner = async (
  params: FetchBannerParams
): Promise<PaginationData<Banner>> => {
  try {
    const res = await api.get<PaginationData<Banner>>('builder/banner', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const bannerService = {
  fetchBanner
}
