import { FC } from 'react'

export const VisionMission: FC = () => {
  return (
    <div className="bg-neutral-100 py-8 md:py-10 mt-10 md:mt-40">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <h1 className="text-2xl font-medium text-neutral-900">
          Definition
        </h1>
        <div className="md:grid md:grid-cols-3 md:gap-10 mt-4">
          <div>
            <p className="text-md text-neutral-900">
              John Robert Powers provides training for individuals that result in lifetime benefits
              from personal cofidence development created through the synergy of communictions,
              presentation and image enhancement skills
            </p>
            <p className="text-md font-medium mt-5 md:mt-10 text-neutral-900">
              "Assist in the development of indonesian people and place them alongside those of other nationalities in positions of equal standing"
            </p>
          </div>
          <div className="flex flex-row space-x-5 mt-10 md:mt-0">
            <img
              className="h-8 w-auto"
              src={require('@assets/icons/vision.png')}
              alt="John Robert Powers"
            />
            <div>
              <h2 className="text-xl font-medium leading-relaxed">Vision</h2>
              <p className="text-md mt-2 text-neutral-900">
                To be the primary source for
                individuals who wish to achieve
                their full potential through personality
                development
              </p>
            </div>
          </div>
          <div className="flex flex-row space-x-5 mt-10 md:mt-0">
            <img
              className="h-8 w-auto"
              src={require('@assets/icons/mission.png')}
              alt="John Robert Powers"
            />
            <div>
              <h2 className="text-xl font-medium leading-relaxed">Mission</h2>
              <p className="text-md mt-2 text-neutral-900">
                To help enrollees to maximize
                their full potential through self-discovery
                image enchancement
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
