import { FC, useEffect, useState } from 'react'
import Slider from 'react-slick'

import { useStoreActions, useStoreState } from '@stores'

export const Banner: FC = () => {
  const { fetchBanner } = useStoreActions(action => action.banner)
  const { data } = useStoreState(state => state.banner)

  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    fetchBanner({
      page: 1,
      limit: 100
    })
  }, [fetchBanner])

  const setting = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6">
      <div className="flex flex-row flex-wrap-reverse justify-between">
        <div className="flex" />
        <Slider
          {...setting}
          className="w-full md:w-4/6 h-auto"
          afterChange={setIndex}
        >
          {data.map((item, index) =>
            <div key={index}>
              <img
                src={item?.image?.url}
                alt="John Robert Powers"
              />
            </div>
          )}
        </Slider>
        {data.length > 0 &&
          <div className="relative md:absolute md:max-w-lg backdrop-blur-md bg-white/60 p-5 rounded-2xl self-center">
            <h1 className="text-3xl font-semibold leading-relaxed text-neutral-900 text-center md:text-left">
              {data[index].title}
            </h1>
            <p className="text-md pt-5 text-neutral-700 text-center md:text-left">
              {data[index].short_description}
            </p>
            <div className="text-center md:text-left mt-10">
              <a
                className="bg-gradient-to-r from-red-600 to-red-700  rounded-md px-10 py-2 text-white font-medium"
                href="https://wa.me/6281380804885"
                target="_blank"
                rel="noreferrer"
              >
                Let's Join Now
              </a>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
