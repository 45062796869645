import { Fragment, useRef, useState, useEffect, FC } from 'react'
import { ScrollRestoration, useNavigate, useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { Topbar, Footer } from '@components'
import { classNames } from '@utils'
import { Program, useStoreActions, useStoreState } from '@stores'
import { ROUTES } from '@routes'

export const OurPrograms: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { fetchProgram } = useStoreActions(action => action.program)
  const { data } = useStoreState(state => state.program)

  const [selectedProgram, setSelectedProgram] = useState<Program>()

  const menu = useRef<HTMLDivElement>(null)

  const convertCase = (title: string) => title
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .replace('&', 'and')
    .toLowerCase()

  useEffect(() => {
    fetchProgram({
      page: 1,
      limit: 100
    })
  }, [fetchProgram])

  useEffect(() => {
    if (!selectedProgram && data.length > 0) {
      if (id && data) {
        const program = data.find(item => convertCase(item.name) === id)
        if (!program) {
          navigate(ROUTES.NotFound)
        } else {
          setSelectedProgram(program || data[0])
        }
      } else {
        setSelectedProgram(data[0])
        navigate(`${ROUTES.OurPrograms}/${convertCase(data[0].name)}`)
      }
    }
  }, [id, data, selectedProgram, navigate])

  return (
    <Fragment>
      <Topbar active={2} />
      <div className="relative bg-black">
        <img
          className="hidden md:block w-full h-auto opacity-50"
          src={require('@assets/images/banner_program_1.png')}
          alt="John Robert Powers"
        />
        <div
          className="h-80 md:hidden bg-cover opacity-50"
          style={{ backgroundImage: `url(${require('@assets/images/banner_program_1.png')})` }}
        />
        <div className="absolute top-0 w-full h-full">
          <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <div className="absolute top-5 md:top-1/2 md:-translate-y-1/2">
              <h1 className="w-72 text-2xl md:text-4xl font-semibold text-white md:leading-relaxed">
                Our Programs Made For You
              </h1>
              <p className="w-80 text-md leading-relaxed text-white mt-2 md:mt-5">
                We provide various special programs for all of you
              </p>
            </div>
          </div>
          <img
            className="absolute w-auto h-4/6 md:h-5/6 right-0 bottom-0"
            src={require('@assets/images/peoples_1.png')}
            alt="John Robert Powers"
          />
        </div>
      </div>
      <div ref={menu} />
      <div className="md:hidden sticky top-16 mx-auto max-w-7xl px-4 sm:px-6 bg-white z-40">
        <div className="md:flex pt-6 gap-20">
          <div className="md:w-1/4">
            <h2 className="text-xl font-semibold text-neutral-900 leading-loose">
              Program Development
            </h2>
            <div className="sticky top-56 col-span-6 sm:col-span-3 md:hidden border-b border-b-gray-200 pb-5">
              <select
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                onChange={e => {
                  const program = data.find(item => item.name === e.target.value)
                  if (program) {
                    setSelectedProgram(program)
                    menu.current?.scrollIntoView()
                    navigate(`${ROUTES.OurPrograms}/${convertCase(program.name)}`, { preventScrollReset: true })
                  }
                }}
                value={selectedProgram ? selectedProgram?.name : 'false'}
              >
                {data.map((item, index) =>
                  <option
                    key={index}
                    value={item.name}
                  >
                    {item.name}
                  </option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="md:flex pb-6 md:py-10 gap-20">
          <div className="md:w-1/4">
            <h2 className="hidden md:block text-xl font-semibold text-neutral-900 leading-loose">
              Program Development
            </h2>
            <ul className="hidden md:block mt-5">
              {data.map((item, index) =>
                <li
                  key={index}
                  className={classNames(
                    'text-md font-medium py-3 border-b border-neutral-300 hover:text-red-600 cursor-pointer',
                    selectedProgram ? (selectedProgram?.name === item?.name ? 'text-red-600' : 'text-neutral-900') : ''
                  )}
                  onClick={() => {
                    setSelectedProgram(item)
                    navigate(`${ROUTES.OurPrograms}/${convertCase(item.name)}`, { preventScrollReset: true })
                  }}
                >
                  {item?.name}
                </li>
              )}
            </ul>
          </div>
          <div className="mt-5 md:mt-0 md:w-3/4">
            <h1 className="text-xl md:text-4xl font-semibold text-neutral-900">
              {selectedProgram?.name}
            </h1>
            <p className="text-md text-neutral-900 leading-relaxed mt-5">
              {selectedProgram?.full_description}
            </p>
            <div className="grid grid-cols-2 gap-3 md:gap-5 mt-10">
              {selectedProgram?.images?.map((item, index) =>
                <Zoom key={index}>
                  <img
                    className="w-full h-auto rounded-2xl"
                    src={item?.url}
                    alt="John Robert Powers"
                  />
                </Zoom>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer active={2} />
      <ScrollRestoration />
    </Fragment>
  )
}