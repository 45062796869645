import { Fragment, useState, useEffect, FC } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { PlayCircleIcon } from '@heroicons/react/24/solid'

import { Topbar, Footer } from '@components'
import { Video, useStoreActions, useStoreState } from '@stores'


export const Videos: FC = () => {
  const { fetchVideo } = useStoreActions(action => action.video)
  const { data } = useStoreState(state => state.video)

  const [selectedVideo, setSelectedVideo] = useState<Video>()
  const [showPlayer, setShowPlayer] = useState<boolean>(false)

  useEffect(() => {
    fetchVideo({
      page: 1,
      limit: 100
    })
  }, [fetchVideo])

  return (
    <Fragment>
      <Topbar active={4} />
      {data.length > 0 &&
        <div className="relative flex justify-center items-center">
          <img
            width="100%"
            src={data[0].video_thumbnail.url.replace('maxres', 'hq')}
            alt="video"
          />
          <PlayCircleIcon
            className="absolute h-10 w-10 sm:h-20 sm:w-20 flex-shrink-0 text-red-600 hover:text-red-800 bg-white rounded-full cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              setSelectedVideo(data[0])
              setShowPlayer(true)
            }}
          />
        </div>
      }
      {data.length > 1 &&
        <div className="p-0 sm:p-10 mt-5 sm:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10">
            {data.slice(1).map((item, index) =>
              <div
                key={index}
                className="relative flex justify-center items-center">
                <img
                  width="100%"
                  src={item.video_thumbnail.url}
                  alt="video"
                />
                <PlayCircleIcon
                  className="absolute h-10 w-10 sm:h-20 sm:w-20 flex-shrink-0 text-red-600 hover:text-red-800 bg-white rounded-full cursor-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    setSelectedVideo(item)
                    setShowPlayer(true)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      }
      <Transition.Root show={showPlayer} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setShowPlayer}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black shadow-xl transition-all">
                  <iframe
                    title="video"
                    className="aspect-video w-full sm:w-[120vh] border-0"
                    src={`https://www.youtube.com/embed/${selectedVideo?.video_id}`}
                    allowFullScreen
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Footer active={2} />
      <ScrollRestoration />
    </Fragment>
  )
}
