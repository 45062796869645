import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchTestimonialParams, Testimonial } from '.'

const fetchTestimonial = async (
  params: FetchTestimonialParams
): Promise<PaginationData<Testimonial>> => {
  try {
    const res = await api.get<PaginationData<Testimonial>>('builder/testimonial', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const testimonialService = {
  fetchTestimonial
}
