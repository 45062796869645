import { FC, Fragment, useEffect } from 'react'
import { ScrollRestoration } from 'react-router-dom'

import { Topbar, Footer } from '@components'
import { useStoreActions, useStoreState } from '@stores'

export const Faq: FC = () => {
  const { fetchFaq } = useStoreActions(action => action.faq)
  const { data } = useStoreState(state => state.faq)

  useEffect(() => {
    fetchFaq({
      page: 1,
      limit: 100
    })
  }, [fetchFaq])

  return (
    <Fragment>
      <Topbar active={3} />
      <div className="relative bg-black">
        <img
          className="hidden md:block w-full h-auto opacity-50"
          src={require('@assets/images/banner_program_1.png')}
          alt="John Robert Powers"
        />
        <div
          className="h-80 md:hidden bg-cover opacity-50"
          style={{ backgroundImage: `url(${require('@assets/images/banner_program_1.png')})` }}
        />
        <div className="absolute top-0 w-full h-full">
          <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <div className="absolute top-5 md:top-1/2 md:-translate-y-1/2">
              <h1 className="w-80 text-2xl md:text-4xl font-semibold text-white md:leading-relaxed">
                Frequently Asked Question
              </h1>
              <p className="text-md leading-relaxed text-white mt-2 md:mt-5">
                Everything you need to know about the programs.
              </p>
            </div>
          </div>
          <img
            className="absolute w-auto h-4/6 md:h-5/6 right-0 bottom-0"
            src={require('@assets/images/peoples_2.png')}
            alt="John Robert Powers"
          />
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="md:flex py-6 md:py-10 gap-20">
          <div className="md:w-1/4">
            <h2 className="text-3xl font-semibold text-neutral-900 leading-loose">
              FAQs
            </h2>
            <p className="text-md text-neutral-900 leading-relaxed mt-5">
              Have you can find frequently asked question. We help you to find the answer.
            </p>
          </div>
          <div className="mt-5 md:mb-20 md:mt-0 md:w-3/4">
            <dl className="mt-2 divide-y divide-slate-100">
              {data.map((item, index) =>
                <details
                  key={index}
                  className="group py-4 marker:content-['']"
                >
                  <summary className="flex w-full cursor-pointer select-none justify-between font-medium leading-7 text-neutral-900 text-left group-open:text-red-900">
                    {item?.question}
                    <svg className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-red-900" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M18 12H6"></path>
                      <path className="group-open:hidden" d="M12 6v12"></path>
                    </svg>
                  </summary>
                  <div className="pt-6 pb-6">
                    <p>{item?.answer}</p>
                  </div>
                </details>
              )}
            </dl>
          </div>
        </div>
      </div>
      <Footer active={3} />
      <ScrollRestoration />
    </Fragment>
  )
}
