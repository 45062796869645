import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchVideoParams, Video } from '.'

const fetchVideo = async (
  params: FetchVideoParams
): Promise<PaginationData<Video>> => {
  try {
    const res = await api.get<PaginationData<Video>>('builder/video', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const VideoService = {
  fetchVideo
}
