import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchBranchParams, Branch } from '.'

const fetchBranch = async (
  params: FetchBranchParams
): Promise<PaginationData<Branch>> => {
  try {
    const res = await api.get<PaginationData<Branch>>('builder/branch-office', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const branchService = {
  fetchBranch
}
