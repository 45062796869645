import { FC, useEffect, useState } from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { Branch, useStoreActions, useStoreState } from '@stores'

export const BranchOffice: FC = () => {
  const { fetchBranch } = useStoreActions(action => action.branch)
  const { data } = useStoreState(state => state.branch)

  const [keyword, setKeyword] = useState<string>('')
  const [selectedBranch, setSelectedBranch] = useState<Branch>()

  useEffect(() => {
    fetchBranch({
      page: 1,
      limit: 100
    })
  }, [fetchBranch])

  const maps = [
    { id: 1, name: 'Kelapa Gading Jakarta', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.782945961246!2d106.905104!3d-6.1598180000000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5316a655555%3A0x389a4c549c1e5b46!2sJohn%20Robert%20Powers%20Kelapa%20Gading!5e0!3m2!1sid!2sid!4v1714155824730!5m2!1sid!2sid' },
    { id: 2, name: 'Sudirman Jakarta', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.427300490092!2d106.8213538!3d-6.2072331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f400540900ed%3A0xd41a821e9fa48ed!2sJohn%20Robert%20Powers%20Sudirman!5e0!3m2!1sid!2sid!4v1714155316205!5m2!1sid!2sid' },
    { id: 3, name: 'BSD City Tangerang', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.699496038729!2d106.65326019999999!3d-6.303157499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb3d559438e7%3A0x802005b443212d24!2sJohn%20Robert%20Powers%20BSD!5e0!3m2!1sid!2sid!4v1714155375562!5m2!1sid!2sid' },
    { id: 4, name: 'Surabaya', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.5745465212954!2d112.67767289999999!3d-7.2891505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbded489d53f%3A0x583be4798e915608!2sJohn%20Robert%20Powers%20-%20Surabaya!5e0!3m2!1sid!2sid!4v1714155402010!5m2!1sid!2sid' },
    { id: 5, name: 'Bali', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.737439062811!2d115.2059144!3d-8.716463999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2409167b0fda9%3A0x911a7c9b4bb4fec3!2sJohn%20Robert%20Powers%20Bali!5e0!3m2!1sid!2sid!4v1714155427056!5m2!1sid!2sid' },
  ]

  return (
    <div className="bg-neutral-100 py-8 md:py-10 mt-10 md:mt-40">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex flex-wrap">
          <div className="w-full md:w-8/12">
            <h1 className="text-2xl font-medium text-neutral-900">
              Our Branch
            </h1>
            <p className="mt-5 text-neutral-600">
              Sebelum memulai konsultasi mohon pilih domisili terdekat Anda. Anda tidak perlu khawatir karena kelas diadakah secara hyrid yaitu online dan onsite, anda dapat memilih full online atau full onsite.
            </p>
            <div className="flex flex-row mt-10 gap-2 md:gap-5">
              <input
                className="w-full md:w-80 p-4 rounded-xl border border-gray-300 focus:border-gray-400 sm:text-sm text-neutral-900 placeholder:text-neutral-400"
                type="text"
                placeholder="Cari Domisili Anda"
                onChange={e => setKeyword(e.target.value)}
              />
            </div>
          </div>
          <div className="hidden md:block w-4/12">
            {/* <img
                className="h-auto w-auto -mt-20"
                src={require('@assets/images/map.png')}
                alt="John Robert Powers"
              /> */}
            <iframe
              title="map"
              className="h-[18vw] w-full -mt-20 rounded-xl border border-[#cbd5e1]"
              src={maps.find(item => item.name.toLowerCase().includes((keyword || selectedBranch?.name || 'Kelapa Gading').toLowerCase()))?.map}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-5 mt-5 md:mt-20">
          {data.filter(item => item.name.toLowerCase().includes(keyword.toLowerCase()))
            .map((item, index) =>
              <div
                key={index}
                className="flex flex-row w-full md:w-[32.222222%] bg-white p-3 rounded-xl gap-3 cursor-default"
                onMouseEnter={() => setSelectedBranch(item)}
              >
                <div className="w-20 h-20 flex-shrink-0">
                  <Zoom>
                    <img
                      className="w-20 h-20 rounded-md"
                      src={item?.image ? item?.image?.url : require('@assets/images/branch.png')}
                      alt="John Robert Powers"
                    />
                  </Zoom>
                </div>
                <div>
                  <h2 className="font-semibold text-md text-neutral-900">
                    {item?.name}
                  </h2>
                  <p className="text-sm text-neutral-900 mt-2">
                    {item?.address}
                  </p>
                  <p className="text-sm font-medium text-neutral-900 mt-2">
                    {item?.phone} / WA: <a href={`https://wa.me/62${item?.whatsapp?.slice(1)}`}>{item?.whatsapp}</a>
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}
