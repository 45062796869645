import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchFaqParams, Faq } from '.'

const fetchFaq = async (
  params: FetchFaqParams
): Promise<PaginationData<Faq>> => {
  try {
    const res = await api.get<PaginationData<Faq>>('builder/faq', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const faqService = {
  fetchFaq
}
