import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { BannerModel, banner } from './banner'
import { BranchModel, branch } from './branch'
import { ContactModel, contact } from './contact'
import { FaqModel, faq } from './faq'
import { ProgramModel, program } from './program'
import { TestimonialModel, testimonial } from './testimonial'
import { VideoModel, video } from './video'

export * from './banner'
export * from './branch'
export * from './contact'
export * from './faq'
export * from './program'
export * from './testimonial'
export * from './video'

export interface StoreModel {
  banner: BannerModel
  branch: BranchModel
  contact: ContactModel
  faq: FaqModel
  program: ProgramModel
  testimonial: TestimonialModel
  video: VideoModel
}

const storeModel: StoreModel = {
  banner,
  branch,
  contact,
  faq,
  program,
  testimonial,
  video
}

const storeConfig: EasyPeasyConfig = {
  devTools: process.env.NODE_ENV !== 'production'
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
