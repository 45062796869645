export const ROUTES = {
  Home: '/home',
  OurPrograms: '/our-programs',
  Faq: '/faq',
  Videos: '/videos',
  Alumni: '/alumni',
  ContactUs: '/contact-us',
  Thanks: '/thank-you',
  NotFound: '/404',
  PrivacyPolicy: '/privacy-policy'
}
