import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { useStoreActions, useStoreState } from '@stores'

export const Program: FC = () => {
  const { fetchProgram } = useStoreActions(action => action.program)
  const { data } = useStoreState(state => state.program)

  useEffect(() => {
    fetchProgram({
      page: 1,
      limit: 100
    })
  }, [fetchProgram])

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 py-8 md:py-24">
      <div className="md:flex md:flex-row md:items-center md:space-x-40">
        <h1 className="w-full md:w-44 text-2xl font-semibold text-black leading-relaxed">
          Our Programs
          Made For You
        </h1>
        <p className="w-full md:w-80 text-md leading-relaxed text-neutral-600">
          We provide various special programs for all of you
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-20 mt-10 md:mt-20">
        {data.map((item, index) =>
          <div key={index}>
            <Zoom>
              <img
                className="w-full h-auto"
                src={item?.images?.[0]?.url}
                alt="John Robert Powers"
              />
            </Zoom>
            <div className="p-5">
              <h2 className="text-2xl md:text-3xl font-semibold text-center text-neutral-900">
                {item?.name}
              </h2>
              <p className="text-md text-neutral-600 text-center mt-4">
                {item?.short_description}
              </p>
            </div>
            <div className="text-md font-medium text-sky-600 text-center">
              <Link to="/our-programs">
                View More &gt;
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
