import { FC, Fragment, useEffect } from 'react'
import { ScrollRestoration } from 'react-router-dom'

import { Topbar, Footer } from '@components'
import { useStoreActions, useStoreState } from '@stores'

export const PrivacyPolicy: FC = () => {
  const { fetchFaq } = useStoreActions(action => action.faq)
  const { data } = useStoreState(state => state.faq)

  useEffect(() => {
    fetchFaq({
      page: 1,
      limit: 100
    })
  }, [fetchFaq])

  return (
    <Fragment>
      <Topbar active={3} /><div className="bg-white py-8">
  <div className="mx-auto max-w-7xl px-4 sm:px-6">
    <div className="flex flex-row justify-between items-end">
      <div>
        <h1 className="text-4xl font-bold text-neutral-900">Privacy Policy</h1>
        <h2 className="text-lg font-normal italic text-neutral-900">Last Updated: Aug 22nd, 2024</h2>
      </div>
    </div>
  </div>
</div>

<div className="mx-auto max-w-7xl px-4 sm:px-6">
  <div className="mb-8">
    <p className="text-md md:text-lg text-neutral-900 leading-relaxed mt-4">
      Thank you for visiting JOHNROBERTPOWERS.CO.ID. Your privacy is crucial to us. This notice outlines our online information practices and the choices you can make regarding the way your information is collected and used. Please read our privacy policies to understand how we treat your personal information.
      <br/><br/>
      <strong>Terms of Use</strong>
      <br/><br/>
      By using our site, you agree to these Terms of Use. If you do not agree, please do not use our site. We reserve the right to modify these Terms of Use at any time. Continued use of our website implies acceptance of the latest Terms. Regularly review these Terms for updates.
      <br/><br/>
      For requests related to the removal from our records, newsletter unsubscription, access or correction of personal information, or privacy inquiries, contact our Data Protection Officer (DPO) at inquiry@johnrobertpowers.com. Please mention that your request is under the Data Privacy Act of 2012. Proof of identity may be required to support your request.
      <br/><br/>
      <strong>How We Collect Information</strong>
      <br/><br/>
      We collect information when you register with JOHNROBERTPOWERS.CO.ID, provide contact details, participate in promotions or surveys, fill out event interest cards, or submit applications. By doing so, you agree to these terms. Changes to our policies will be communicated, and new uses of information will require your permission.
      <br/><br/>
      "Personal information" includes your name, email, phone number, age, gender, occupation, and other relevant details. Non-personally identifiable information like browser type, operating system, and domain name of your ISP may also be collected.
      <br/><br/>
      <strong>How We Store and Use the Information</strong>
      <br/><br/>
      We store the collected information securely and use it to fulfill your requests, respond to inquiries, and offer relevant products, programs, or services. We may also use this information to notify you about promotions, events, or updates.
      <br/><br/>
      Non-personally identifiable information helps us improve our site design and content. We may also use aggregated data to analyze site usage and enhance user experience.
      <br/><br/>
      <strong>Disclosure of Information</strong>
      <br/><br/>
      We disclose information only when required by law, in response to a court order, subpoena, or law enforcement request. Service providers and suppliers with access to personal information must protect it consistently with this Privacy Notice.
      <br/><br/>
      <strong>How We Protect Personal Information</strong>
      <br/><br/>
      John Robert Powers is committed to safeguarding your personal data with physical, technical, and organizational measures against loss, theft, unauthorized access, disclosure, copying, use, or modification.
      <br/><br/>
      <strong>Third-Party Providers</strong>
      <br/><br/>
      We limit access to personal data to our internal staff and third-party providers who market our services. All third-party providers are required to protect your data's confidentiality and privacy.
      <br/><br/>
      <strong>Data Retention</strong>
      <br/><br/>
      Your personal data will be retained by John Robert Powers until destruction, disposal, or anonymization, unless withdrawn in writing or withheld due to changes in provided information.
      <br/><br/>
      <strong>Cookies</strong>
      <br/><br/>
      We use cookies to enhance your browsing experience. Cookies may be set by us or third parties that analyze information. You can control cookies through your browser settings.
      <br/><br/>
      <strong>Special Note For Parents</strong>
      <br/><br/>
      JOHNROBERTPOWERS.CO.ID is intended for users aged 13 and older. If you believe your child under 13 has accessed our site without permission, contact us at inquiry@johnrobertpowers.com.
      <br/><br/>
      <strong>Trademarks, Copyrights, And Restrictions</strong>
      <br/><br/>
      This site is owned by JRP Center, Inc. All materials on this site are protected by intellectual property rights owned or licensed by JRP Center, Inc. You may use materials only for personal, non-commercial use without modification. Copying, reproducing, or distributing materials without permission is prohibited.
      <br/><br/>
      <strong>Transfer of Business Assets</strong>
      <br/><br/>
      In the event of a third-party acquisition of JRP Center, Inc. or its assets, personally identifiable information will be among the transferred assets.
      <br/><br/>
      <strong>How to Contact Us</strong>
      <br/><br/>
      For questions or concerns about our privacy policy, email us at inquiry@johnrobertpowers.com or write to:
      <br/><br/>
      John Robert Powers  <br/>
      Attention: Data Protection Officer  <br/>
      Jend. Sudirman kav. 5-6 Menara Astra 23rd floor,  <br/>
      RT.10/RW.11, Karet, Tengsin, Kota Jakarta Pusat,  <br/>
      Daerah Khusus Ibukota Jakarta 10250<br/>
    </p>
  </div>
</div>


      <Footer active={3} />
      <ScrollRestoration />
    </Fragment>
  )
}
