import { FC } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '@routes'

interface Props {
  active?: number
}

export const Footer: FC<Props> = ({ active }) => {
  return (
    <footer>
      <div className="bg-neutral-100">
        <div className="flex flex-col mx-auto max-w-7xl px-4 py-10 sm:px-6">
          <h1 className="text-xl md:text-3xl font-semibold text-center leading-relaxed md:mt-20">
            Want To Develop Your Personality Skills? <br />
            Book Your FREE Consultation NOW!
          </h1>
          <Link to={ROUTES.ContactUs}
            className="bg-gradient-to-r from-red-600 to-red-700 rounded-md px-10 py-4 text-white font-medium mt-10 text-sm self-center"
          >
            Click here
          </Link>
          <div className="flex flex-wrap items-center justify-between mt-16 md:mt-36">
            <div className="flex justify-center w-full md:w-1/3">
              <Link to="/">
                <span className="sr-only">John Robert Powers</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src={require('@assets/icons/logo-2.png')}
                  alt="John Robert Powers"
                />
              </Link>
            </div>
            <div className="flex justify-center md:justify-end space-x-10 w-full md:w-1/3 mt-8 md:mt-0">
              <a href="https://www.facebook.com/jrpindonesia" target="_blank" rel="noreferrer">
                <img
                  className="h-6 w-auto"
                  src={require('@assets/icons/facebook.png')}
                  alt="John Robert Powers"
                />
              </a>
              <a href="https://www.instagram.com/jrpindonesia" target="_blank" rel="noreferrer">
                <img
                  className="h-6 w-auto"
                  src={require('@assets/icons/instagram.png')}
                  alt="John Robert Powers"
                />
              </a>
              <a href="https://www.twitter.com/jrpindonesia" target="_blank" rel="noreferrer">
                <img
                  className="h-6 w-auto"
                  src={require('@assets/icons/twitter.png')}
                  alt="John Robert Powers"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-neutral-900 ">
        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6">
          <p className="text-xs md:text-md text-center text-white">Copyright ©2022 PT. Jasa Ragam Pengembangan Pribadi - John Robert Powers Indonesia | All Rights Reserved</p>
        </div>
      </div>
    </footer>
  )
}