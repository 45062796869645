import { api } from '@utils'
import { PaginationData } from '@types'

import { FetchProgramParams, Program } from '.'

const fetchProgram = async (
  params: FetchProgramParams
): Promise<PaginationData<Program>> => {
  try {
    const res = await api.get<PaginationData<Program>>('builder/program', { params })
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const ProgramService = {
  fetchProgram
}
