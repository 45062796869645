import CryptoJS from 'crypto-js'

import { api } from '@utils'
import { Data } from '@types'

import { ContactDto, Response } from '.'

const submit = async (dto: ContactDto): Promise<Data<Response>> => {
  try {
    const body = JSON.stringify({ ...dto })

    const timestamp = new Date().getTime()
    const secretKey = 'johnrobertpowers'

    const minifiedBody = timestamp + ";" + JSON.stringify(JSON.parse(body))
    const hash = CryptoJS.HmacSHA512(minifiedBody, secretKey).toString()
    const signature = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(hash))

    const res = await api.post('https://jrp.smartsistem.id/api/lead', body, {
      headers: {
        'Content-Type': 'application/json',
        'Signature': signature,
        'Timestamp': timestamp
      }
    })
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const contactService = {
  submit
}
