import { FC, Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  ChatBubbleLeftRightIcon,
  Square3Stack3DIcon,
  UsersIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline'

import { classNames } from '@utils'
import { ROUTES } from '@routes'

interface Props {
  active?: number
}

export const Topbar: FC<Props> = ({ active }) => {
  const [shadow, setShadow] = useState<boolean>(false)

  const onScroll = () => {
    setShadow(window.scrollY >= 10)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [])
  return (
    <header
      className={classNames(
        'sticky top-0 bg-white z-50 ease-in duration-300',
        shadow ? 'shadow-xl' : 'shadow-none'
      )}>
      <Popover className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:space-x-10">
          {/* <LOGO> */}
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">John Robert Powers</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={require('@assets/icons/logo-2.png')}
                alt="John Robert Powers"
              />
            </Link>
          </div>
          {/* <LOGO/> */}
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </Popover.Button>
          </div>
          {/* <NAV> */}
          <nav className="hidden space-x-10 lg:space-x-20 md:flex">
            <Link to={ROUTES.Home} className={classNames(
              'text-base font-semibold',
              active === 1 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              Home
            </Link>
            <Link to={ROUTES.OurPrograms} className={classNames(
              'text-base font-semibold',
              active === 2 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              Our Programs
            </Link>
            <Link to={ROUTES.Faq} className={classNames(
              'text-base font-semibold',
              active === 3 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              FAQ
            </Link>
            {/* <Link to={ROUTES.Videos} className={classNames(
              'text-base font-semibold',
              active === 4 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              Videos
            </Link> */}
            <Link to={ROUTES.Alumni} className={classNames(
              'text-base font-semibold',
              active === 5 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              Alumni
            </Link>
            <Link to={ROUTES.ContactUs} className={classNames(
              'text-base font-semibold',
              active === 6 ? 'text-red-600 hover:text-red' : 'text-gray-500 hover:text-gray-900'
            )}
            >
              Contact Us
            </Link>
          </nav>
          {/* </NAV> */}
          <div className="hidden items-center justify-end lg:flex lg:flex-1 lg:w-0">
          </div>
        </div>
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-50">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-2 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={require('@assets/icons/logo-2.png')}
                      alt="John Robert Powers"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-3">
                  <nav className="grid gap-y-2">
                    <Link className="-mx-3" to={ROUTES.Home}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <HomeIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 1 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          Home
                        </span>
                      </Popover.Button>
                    </Link>
                    <Link className="-mx-3" to={ROUTES.OurPrograms}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <Square3Stack3DIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 2 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          Our Programs
                        </span>
                      </Popover.Button>
                    </Link>
                    <Link className="-mx-3" to={ROUTES.Faq}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <ChatBubbleLeftRightIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 3 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          FAQ
                        </span>
                      </Popover.Button>
                    </Link>
                    {/* <Link className="-mx-3" to={ROUTES.Videos}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <VideoCameraIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 4 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          Videos
                        </span>
                      </Popover.Button>
                    </Link> */}
                    <Link className="-mx-3" to={ROUTES.Alumni}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <UsersIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 5 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          Alumni
                        </span>
                      </Popover.Button>
                    </Link>
                    <Link className="-mx-3" to={ROUTES.ContactUs}>
                      <Popover.Button className="w-full flex items-center rounded-md p-3 hover:bg-gray-50">
                        <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-red-600" aria-hidden="true" />
                        <span
                          className={classNames(
                            'ml-3 text-base font-medium',
                            active === 6 ? 'text-red-600 font-bold' : 'text-gray-900'
                          )}
                        >
                          Contact Us
                        </span>
                      </Popover.Button>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}