import { FC, Fragment, useEffect } from 'react'
import { ScrollRestoration, useNavigate } from 'react-router-dom'

import { Topbar, Footer } from '@components'
import { useStoreActions, useStoreState } from '@stores'
import { ROUTES } from '@routes'

export const Thanks: FC = () => {
  const { fetchBranch } = useStoreActions(action => action.branch)
  const { data } = useStoreState(state => state.branch)

  const navigate = useNavigate()

  useEffect(() => {
    fetchBranch({
      page: 1,
      limit: 100
    })
  }, [fetchBranch])

  const maps = [
    { id: 1, name: 'Kelapa Gading Jakarta', map: 'https://www.google.com/maps/place/John+Robert+Powers+Kelapa+Gading/@-6.1598127,106.9025291,17z/data=!3m2!4b1!5s0x2e69f548194e844d:0x7514e6a0c5983e6!4m6!3m5!1s0x2e69f5316a655555:0x389a4c549c1e5b46!8m2!3d-6.159818!4d106.905104!16s%2Fg%2F1tfnk7fr?entry=ttu' },
    { id: 2, name: 'Sudirman Jakarta', map: 'https://www.google.com/maps/place/John+Robert+Powers+Sudirman/@-6.2072278,106.8187789,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f400540900ed:0xd41a821e9fa48ed!8m2!3d-6.2072331!4d106.8213538!16s%2Fg%2F11b88mph8t?entry=ttu' },
    { id: 3, name: 'BSD City Tangerang', map: 'https://www.google.com/maps/place/John+Robert+Powers+BSD/@-6.3031522,106.6506853,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69fb3d559438e7:0x802005b443212d24!8m2!3d-6.3031575!4d106.6532602!16s%2Fg%2F11b8jc_n4y?entry=ttu' },
    { id: 4, name: 'Surabaya', map: 'https://www.google.com/maps/place/John+Robert+Powers+-+Surabaya/@-7.2891452,112.675098,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd7fbded489d53f:0x583be4798e915608!8m2!3d-7.2891505!4d112.6776729!16s%2Fg%2F1pv0h8149?entry=ttu' },
    { id: 5, name: 'Bali', map: 'https://www.google.com/maps/place/John+Robert+Powers+Bali/@-8.7164587,115.2033395,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd2409167b0fda9:0x911a7c9b4bb4fec3!8m2!3d-8.716464!4d115.2059144!16s%2Fg%2F1hc3qb4ts?entry=ttu' },
  ]

  return (
    <Fragment>
      <Topbar />
      <div className="relative bg-black">
        <img
          className="w-full h-auto"
          src={require('@assets/images/contact-us.jpg')}
          alt="John Robert Powers"
        />
        <div className="bg-white mb-10 text-sm">
          <div className="mx-auto max-w-6xl w-full mt-0 md:-mt-44">
            <div className="hidden md:block text-3xl font-bold text-white text-center">Thank You</div>
            <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-5">
              <div className="max-w-none md:max-w-md">
                <div className="bg-red-600 text-white p-10">
                  {data.map((data, index) =>
                    <div
                      key={index}
                      className="border-b border-white pb-3 mb-3"
                    >
                      <div className="font-semibold mb-2">{data.name}</div>
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/phone.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.phone}</div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/whatsapp.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.whatsapp}</div>
                      </div>
                      <div className="flex flex-row items-start gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/location.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.address}</div>
                      </div>
                      <div className="mt-2 ml-7 font-semibold cursor-pointer">
                        <a href={maps?.find(item => item.name === data.name)?.map} target="_blank" rel="noreferrer">View Map »</a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="p-10 mt-0 md:mt-28">
                <div className="text-xl">Thanks for getting in touch! one of our representatives will get back to you soon.</div>
                <div className="flex flex-wrap gap-2 mt-5">
                  <div
                    className="w-full md:w-auto bg-gradient-to-r from-red-600 to-red-700 rounded-full px-5 py-2 text-white font-medium text-center cursor-pointer"
                    onClick={() => navigate(ROUTES.Home)}
                  >
                    Back to Homepage
                  </div>
                  <div
                    className="w-full md:w-auto bg-gradient-to-r from-red-600 to-red-700 rounded-full px-5 py-2 text-white font-medium text-center cursor-pointer"
                    onClick={() => navigate(ROUTES.OurPrograms)}
                  >
                    Back to Our Programs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollRestoration />
    </Fragment>
  )
}