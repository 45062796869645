import { FC, Fragment, useEffect, useState } from 'react'
import { ScrollRestoration, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Topbar, Footer } from '@components'
import { useStoreActions, useStoreState } from '@stores'
import { FormStatus } from '@types'
import { ROUTES } from '@routes'

export const ContactUs: FC = () => {
  const { fetchBranch } = useStoreActions(action => action.branch)
  const { data } = useStoreState(state => state.branch)
  const { fetchProgram } = useStoreActions(action => action.program)
  const { data: program } = useStoreState(state => state.program)

  const { submit } = useStoreActions(action => action.contact)
  const { status, error } = useStoreState(state => state.contact)

  const [tags, setTags] = useState<number[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    fetchBranch({
      page: 1,
      limit: 100
    })
  }, [fetchBranch])

  useEffect(() => {
    fetchProgram({
      page: 1,
      limit: 100
    })
  }, [fetchProgram])

  useEffect(() => {
    if (status === FormStatus.ERROR && error) {
      alert(error)
    }
  }, [status, error])

  useEffect(() => {
    if (status === FormStatus.SUCCESS) navigate(ROUTES.Thanks)
  }, [status, navigate])

  const maps = [
    { id: 1, name: 'Kelapa Gading Jakarta', map: 'https://www.google.com/maps/place/John+Robert+Powers+Kelapa+Gading/@-6.1598127,106.9025291,17z/data=!3m2!4b1!5s0x2e69f548194e844d:0x7514e6a0c5983e6!4m6!3m5!1s0x2e69f5316a655555:0x389a4c549c1e5b46!8m2!3d-6.159818!4d106.905104!16s%2Fg%2F1tfnk7fr?entry=ttu' },
    { id: 2, name: 'Sudirman Jakarta', map: 'https://www.google.com/maps/place/John+Robert+Powers+Sudirman/@-6.2072278,106.8187789,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f400540900ed:0xd41a821e9fa48ed!8m2!3d-6.2072331!4d106.8213538!16s%2Fg%2F11b88mph8t?entry=ttu' },
    { id: 3, name: 'BSD City Tangerang', map: 'https://www.google.com/maps/place/John+Robert+Powers+BSD/@-6.3031522,106.6506853,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69fb3d559438e7:0x802005b443212d24!8m2!3d-6.3031575!4d106.6532602!16s%2Fg%2F11b8jc_n4y?entry=ttu' },
    { id: 4, name: 'Surabaya', map: 'https://www.google.com/maps/place/John+Robert+Powers+-+Surabaya/@-7.2891452,112.675098,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd7fbded489d53f:0x583be4798e915608!8m2!3d-7.2891505!4d112.6776729!16s%2Fg%2F1pv0h8149?entry=ttu' },
    { id: 5, name: 'Bali', map: 'https://www.google.com/maps/place/John+Robert+Powers+Bali/@-8.7164587,115.2033395,17z/data=!3m1!4b1!4m6!3m5!1s0x2dd2409167b0fda9:0x911a7c9b4bb4fec3!8m2!3d-8.716464!4d115.2059144!16s%2Fg%2F1hc3qb4ts?entry=ttu' },
  ]

  const ContactSchema = yup.object().shape({
    branch_office_id: yup.string().required('required'),
    first_name: yup.string().required('required'),
    last_name: yup.string().required('required'),
    email: yup.string().required('required').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, 'email not valid'),
    phone_number: yup.string().required('required'),
    age: yup.string().required('required'),
    occupation: yup.string().required('required'),
    note: yup.string().required('required')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      branch_office_id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      age: '',
      occupation: '',
      note: '',
      program_tags: '[4]'
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ContactSchema,
    onSubmit: values => {
      submit({ ...values, program_tags: JSON.stringify(tags) })
    }
  })

  return (
    <Fragment>
      <Topbar active={6} />
      <div className="relative bg-black">
        <img
          className="w-full h-auto"
          src={require('@assets/images/contact-us.jpg')}
          alt="John Robert Powers"
        />
        <div className="bg-white mb-10 text-sm">
          <div className="mx-auto max-w-6xl w-full mt-0 md:-mt-44">
            <div className="hidden md:block text-3xl font-bold text-white text-center">Contact Us</div>
            <div className="flex flex-col md:flex-row mt-0 md:mt-5">
              <div className="max-w-none md:max-w-md">
                <div className="bg-red-600 text-white p-10">
                  {data.map((data, index) =>
                    <div
                      key={index}
                      className="border-b border-white pb-3 mb-3"
                    >
                      <div className="font-semibold mb-2">{data.name}</div>
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/phone.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.phone}</div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/whatsapp.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.whatsapp}</div>
                      </div>
                      <div className="flex flex-row items-start gap-2">
                        <div className="flex flex-row justify-center items-center flex-shrink-0 h-5 w-5">
                          <img
                            className="h-3"
                            src={require('@assets/icons/location.png')}
                            alt="icon"
                          />
                        </div>
                        <div className="leading-loose">{data.address}</div>
                      </div>
                      <div className="mt-2 ml-7 font-semibold cursor-pointer">
                        <a href={maps?.find(item => item.name === data.name)?.map} target="_blank" rel="noreferrer">View Map »</a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="p-10 mt-0 md:mt-28">
                  <div>We are more than ready to be your partners to success! Got anything in mind or ready to enroll? Hit us up!</div>
                  <div className="mt-5">
                    <div className="flex flex-wrap gap-5 mb-6">
                      <select
                        className="w-full border-2 border-black p-2"
                        name="branch_office_id"
                        value={values.branch_office_id}
                        onChange={handleChange}
                      >
                        <option value="" disabled hidden>Preferred Branch</option>
                        {data.map((data, index) =>
                          <option
                            key={index}
                            value={data.id}
                          >
                            {data.name}
                          </option>
                        )}
                      </select>
                      {errors.branch_office_id &&
                        <div className="-mt-5 text-red-800">
                          *{errors.branch_office_id}
                        </div>
                      }
                    </div>
                    <div className="flex flex-wrap gap-5 mb-6">
                      <div className="w-full md:flex-1">
                        <input
                          className="w-full border-2 border-black p-2"
                          name="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name &&
                          <div className="text-red-800">
                            *{errors.first_name}
                          </div>
                        }
                      </div>
                      <div className="w-full md:flex-1">
                        <input
                          className="w-full border-2 border-black p-2"
                          name="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        {errors.first_name &&
                          <div className="text-red-800">
                            *{errors.first_name}
                          </div>
                        }
                      </div>
                      <div className="flex-1 md:flex-none md:w-20">
                        <input
                          type="number"
                          className="w-full border-2 border-black p-2"
                          name="age"
                          placeholder="Age"
                          value={values.age}
                          onChange={e => handleChange('age')(e.target.value.toString())}
                        />
                        {errors.age &&
                          <div className="text-red-800">
                            *{errors.age}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-5 mb-6">
                      <div className="w-full md:flex-1">
                        <input
                          type="email"
                          className="w-full border-2 border-black p-2"
                          name="email"
                          placeholder="Email Address"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email &&
                          <div className="text-red-800">
                            *{errors.email}
                          </div>
                        }
                      </div>
                      <div className="w-full md:flex-1">
                        <input
                          type="tel"
                          className="w-full border-2 border-black p-2"
                          name="phone_number"
                          placeholder="Contact No. (Required)"
                          value={values.phone_number}
                          onChange={handleChange}
                        />
                        {errors.phone_number &&
                          <div className="text-red-800">
                            *{errors.phone_number}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-5 mb-6">
                      <input
                        className="w-full border-2 border-black p-2"
                        name="occupation"
                        placeholder="Occupation / School"
                        value={values.occupation}
                        onChange={handleChange}
                      />
                      {errors.occupation &&
                        <div className="-mt-5 text-red-800">
                          *{errors.occupation}
                        </div>
                      }
                    </div>
                    <div className="flex flex-row gap-1.5 flex-wrap mb-4">
                      {program.map((item, index) =>
                        <div
                          key={index}
                          className={`border-2 border-black whitespace-nowrap py-1 px-2.5 rounded-full text-xs font-semibold select-none cursor-pointer ${tags.includes(item.id) ? 'bg-black text-white' : 'bg-white text-black'}`}
                          onClick={() => {
                            const id = tags.findIndex(id => id === item.id)
                            if (id === -1) tags.push(item.id)
                            else tags.splice(id, 1)
                            setTags([...tags])
                          }}
                        >
                          {item.name}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-5 mb-6">
                      <textarea
                        className="w-full h-40 border-2 border-black p-2 resize-none"
                        name="note"
                        placeholder="Write something..."
                        value={values.note}
                        onChange={handleChange}
                      />
                      {errors.note &&
                        <div className="-mt-5 text-red-800">
                          *{errors.note}
                        </div>
                      }
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="bg-red-600 rounded-full px-10 py-2 text-white font-medium w-full md:w-auto"
                        disabled={status === FormStatus.LOADING}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer active={6} />
      <ScrollRestoration />
    </Fragment>
  )
}