import { FC, Fragment, useEffect, useState } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'

import { Topbar, Footer } from '@components'
import { Video, useStoreActions, useStoreState } from '@stores'

export const Alumni: FC = () => {
  const { fetchVideo } = useStoreActions(action => action.video)
  const { data } = useStoreState(state => state.video)

  const [selectedVideo, setSelectedVideo] = useState<Video>()
  const [showPlayer, setShowPlayer] = useState<boolean>(false)

  useEffect(() => {
    fetchVideo({
      page: 1,
      limit: 100
    })
  }, [fetchVideo])

  return (
    <Fragment>
      <Topbar active={5} />
      <div className="bg-white py-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <div className="flex flex-row justify-between items-end">
            <div>
              <h1 className="text-4xl font-bold text-neutral-900">Alumni</h1>
              <h2 className="text-lg font-normal italic text-neutral-900">Alumni Success Histories</h2>
            </div>
            <div>
              <div className="text-sm italic">Success is Where Preparation & Opportunity Meet.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          {data.map((data, index) =>
            <div
              key={index}
              className="flex flex-col md:flex-row justify-between gap-5 bg-black rounded-xl p-10 mb-10"
            >
              <div className="flex-1">
                <div className="text-3xl font-medium text-white">{data.title}</div>
                <div className="text-sm font-light text-white mt-5">{data.description}</div>
                <div className="flex flex-col md:flex-row gap-5 mt-10">
                  <div
                    className="flex flex-row justify-center items-center gap-2 bg-white py-1 px-4 rounded-full cursor-pointer"
                    onClick={() => {
                      setSelectedVideo(data)
                      setShowPlayer(true)
                    }}
                  >
                    <div className="text-red">Listen on</div>
                    <img
                      className="h-5"
                      src={require('@assets/icons/youtube.png')}
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <img
                  className="w-full"
                  src={data.video_thumbnail.url.replace('maxres', 'hq')}
                  alt="thumbnail"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={showPlayer} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setShowPlayer}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black shadow-xl transition-all">
                  <iframe
                    title="video"
                    className="aspect-video w-full sm:w-[120vh] border-0"
                    src={`https://www.youtube.com/embed/${selectedVideo?.video_id}`}
                    allowFullScreen
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Footer active={2} />
      <ScrollRestoration />
    </Fragment>
  )
}